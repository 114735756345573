<template>
    <div class="christmas-page">
        <van-nav-bar :border="false" :placeholder="false" :fixed="true" class="christmas-top"
                     :left-text="$t('返回')"
                     left-arrow @click-left="onClickLeft"/>
<!--        <div class="b-lr b-left"></div>-->
<!--        <div class="b-lr b-right"></div>-->
        <div class="top-img" style="display: flex;align-items: center;justify-content: center">
            <img src="../../assets/pictures/christmas/top.png"/>
<!--            <div class="t-img-title">-->
<!--                <img src="../../assets/pictures/christmas/top-title.png"/>-->
<!--            </div>-->
            <div class="t-data" style="position: absolute;top: 100px;" v-if="data.info">
                <div class="t-yq">
                    {{$t('已成功邀请人数')}} : {{data.info.inv_count}}
                </div>
                <div class="t-cj">
                    {{$t('剩余抽奖次数')}} : {{data.info.count}}
                </div>
            </div>
        </div>
        <div class="christmas-main">
            <div class="zp-main">
                <img src="../../assets/pictures/christmas/9gg22.png" class="m-img-gg">
                <div class="jp-rows">
                    <div class="zd" v-if="zd_flag">
                        <div class="zailaiyici" @click="reset_lw">{{$t('再来一次')}}</div>
                    </div>
                    <van-col span="8" v-for="item in lw_list" :key="item.key" class="jp-col">
                        <div style="justify-content: center;align-items: center">
                            <img :src="item.url" class="lw-img" @click="choujiang">
                        </div>
                    </van-col>
                </div>
            </div>
            <van-button size="small" class="btn" @click="isRule = true">{{ $t('规则') }}</van-button>
            <div class="zjmd">
<!--                <div class="hj-img">-->
<!--                    <img src="../../assets/pictures/christmas/hj-title.png">-->
<!--                </div>-->
                <div class="hj-main" style="padding-top: 20px">
                    <div class="hj-header flex-hj">
                        <div>{{$t('奖品')}}</div>
                        <div>{{$t('用户')}}</div>
                        <!--                        <div>{{$t('时间')}}</div>-->
                    </div>
                    <div class="hj-body flex-hj" v-for="(item,index) in data.list" :key="index">
                        <div>{{item.d_prize_desc}}</div>
                        <div>{{item.phone}}</div>
                    </div>
                </div>
                <div class="hj-bottom">
                    {{$t('Arraste para cima e para baixo para saber mais')}}
                </div>
            </div>
            <div class="zjmd" style="margin-top: 15px" v-if="false">
                <div class="hj-img" style="position: relative">
<!--                    <img src="../../assets/pictures/christmas/gz-title.png">-->
                </div>
                <div class="hj-main" style="padding-top: 20px;">
                    <div class="sdtree">
                        <img src="../../assets/pictures/christmas/sdtree.png">
                    </div>
                    <div class="rule-content">
                        <div class="r-desc" v-html="$t('抽奖规则说明')"></div>
                        <div class="r-prize">
                            <div class="r-prize-title">{{$t('奖品列表')}}</div>
                            <div class="r-prize-list">
                                <ul>
                                    <li v-for="(item, index) in data.prize" :key="index">{{$t(item)}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <van-popup v-model="isRule" :round="true" close-icon-position="top-left" >
            <div class="zjmd" style="min-width:280px;" :closeable="true">
                <img @click="isRule = false" :src="require('../../assets/pictures/christmas/close.png')" alt="" class="close-icon">
                <div class="hj-main" style="padding-top: 20px;">
                    <div class="sdtree">
                        <img src="../../assets/pictures/christmas/sdtree.png">
                    </div>
                    <div class="rule-content">
                        <div class="r-desc" v-html="$t('抽奖规则说明')"></div>
                        <div class="r-prize">
                            <div class="r-prize-title">{{$t('奖品列表')}}</div>
                            <div class="r-prize-list">
                                <ul>
                                    <li v-for="(item, index) in data.prize" :key="index">{{$t(item)}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
        <van-popup class="dialog-notice dialog-notice2" v-model="p_visible"
                   position="center" closeable
                   :close-icon="require('../../assets/pictures/christmas/close.png')"
                   style="background-color:unset;min-height: 235px"
                   :style="{ borderRadius: '10px', width: '80%'}"
                   :close-on-click-overlay="false">
            <div class="p_content">
                <div class="p-d-lh">
                    <img src="../../assets/pictures/christmas/lh.png" class="p-lh"/>
                </div>
                <div class="p-d-lw">
                    <img :src="prize_src" class="p-lw" />
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                isRule:false,
                p_visible:false,
                name: '',
                data: '',
                zd_flag: false,
                lw_list: [],
                lw_cols: 3,
                prize_src: require('../../assets/pictures/christmas/lw.png'),
                prize_list: [
                    require('../../assets/pictures/christmas/p1.png'),
                    require('../../assets/pictures/christmas/p2.png'),
                    require('../../assets/pictures/christmas/p3.png'),
                    require('../../assets/pictures/christmas/p4.png'),
                    require('../../assets/pictures/christmas/p5.png'),
                    require('../../assets/pictures/christmas/p6.png'),
                    require('../../assets/pictures/christmas/p7.png'),
                    require('../../assets/pictures/christmas/p8.png'),
                    require('../../assets/pictures/christmas/p9.png'),
                    require('../../assets/pictures/christmas/p10.png'),
                    require('../../assets/pictures/christmas/p11.png'),
                    require('../../assets/pictures/christmas/p12.png'),
                ]
            }
        },
        methods: {
            reset_lw() {
                this.zd_flag = false;
                this.initLw(9);
            },
            initLw(v, key) {
                if (key == undefined) {
                    key = Math.random();
                }
                let list = [];
                for (let i = 0; i < v; i++) {
                    list.push({'url': require('../../assets/pictures/christmas/lw.png'), 'key': i + '_' + key});
                }
                this.lw_list = list;
            },
            choujiang(that) {
                console.log(that.target);
                if( this.zd_flag ){
                    // this.zd_flag = true;
                    // this.p_visible = true;
                    // this.prize_src = require('../../assets/pictures/christmas/lw.png');
                    return;
                }
                this.$axios
                    .get('/christmas/duihuan')
                    .then((res) => {
                        this.data = res.data.data;
                        if (res.data.code == 0) {
                            this.zd_flag = true;
                            that.target.src = this.prize_list[res.data.data.prize.id - 1];
                            this.prize_src = this.prize_list[res.data.data.prize.id - 1];
                            this.getData();
                        } else {
                            this.$toast.fail(res.data.msg);
                        }

                    });
            },
            onClickLeft() {
                this.$router.go(-1)
            },
            getData() {
                this.$axios
                    .get('/christmas/index')
                    .then((res) => {
                        this.data = res.data.data;
                    });
            },
        },
        mounted() {
            this.initLw(9);
            this.getData();
        }

    }
</script>
<style scoped>

    .van-nav-bar {
        background: unset;
    }

    .christmas-page {
        min-height: 100vh;
        background-color: #105fff;
    }

    .t-img-title {
        position: absolute;
        top: 85px;
        left: 0;
        padding: 0 65px;
        /*animation: spin2 2s linear infinite;*/
    }

    .t-data {
        text-align: center;
        font-size: 16px;
        color: #FFFFFF;
    }

    .christmas-main {
        padding: 20px;
    }

    .m-img-gg {
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    .zp-main {
        position: relative;
        width: 100%;
        height: 400px;
    }

    .zd {
        position: absolute;
        background-color: rgba(82, 75, 75, 0.2);
        top: 0;
        left: 0;
        right: 0;
        bottom: 20px;
        z-index: 2000;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #FFFFFF;
    }

    .zailaiyici {
        margin-top: 80px;
        background-color: rgba(136, 44, 42, 0.6);
        padding: 10px 15px;
        border-radius: 15px;
    }

    .jp-rows {
        font-size: 12px;
        position: relative;
        padding: 25px;
        z-index: 10;
        text-align: center;
        height: 100%;
    }

    .jp-row {
        height: 108px;
    }

    .jp-col {
        /*padding: 20px;*/
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 108px;
    }

    .lw-img {
        width: 80px;
        /*animation: spin2 2s linear infinite;*/
    }

    .lw-img:hover {
        animation: spin3 1s linear infinite;
    }

    .b-lr {
        width: 10px;
        height: 1580px;
    }

    .b-left {
        position: absolute;
        background-image: url("../../assets/pictures/christmas/left.png");
        background-repeat: repeat-y;
        background-size: 100% auto;
    }

    .b-right {
        position: absolute;
        right: 0;
        background-image: url("../../assets/pictures/christmas/right.png");
        background-repeat: repeat-y;
        background-size: 100% 100%;
        background-position: center;
        /*height: 100%;*/
    }

    .zjmd {
        position: relative;
        background-color: #4e7bff;
        width: 100%;
        /*height: 400px;*/
        border-radius: 10px;
    }

    .flex-hj {
        display: flex;
        justify-content: space-around;
    }

    .hj-img {
        position: absolute;
        padding: 0 10px;
        /*width: 100%;*/
        z-index: 1;
    }

    .hj-img img {
        /*width: 100%;*/
        /*animation: spin2 2s linear infinite;*/
    }

    .hj-main {
        position: relative;
        z-index: 10;
        padding: 120px 10px 20px 10px;
    }

    .hj-header {
        padding-bottom: 10px;
        color: #FFFFFF;
        font-size: 16px;
        border-bottom: 4px solid #012A2C;
    }

    .hj-body {
        padding: 10px 0;
        color: #FFFFFF;
        font-size: 16px;
        border-bottom: 2px solid #012A2C;
    }

    .hj-bottom {
        text-align: center;
        padding: 0px 10px 20px 10px;
        color: #cccccc;
        font-size: 14px;
    }

    .sdtree {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .sdtree img {
        width: 200px;
    }

    .r-desc {
        padding-bottom: 20px;
    }

    .rule-content {
        font-size: 14px;
        color: #FFFFFF;
        min-height: 350px;
    }

    .r-prize-title {
        color: orange;
    }

    .r-prize-list {
        padding: 10px 20px;
    }

    .r-prize-list ul {
        list-style: inherit;
    }

    .r-prize-list li {
        line-height: 25px;
    }

    .p_content{
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .p-d-lw{
        position: absolute;
        /*width: 100%;*/
        /*height: 100%;*/
        z-index: -1;
        top: 65px;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .p-d-lw img{
        animation: spin3 1s linear infinite;
        animation-iteration-count:3;
    }


    @keyframes spin2 {
        0% {
            opacity: 0.6;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0.6;
        }
    }

    @keyframes spin3 {
        0% {
            transform: rotate(0deg);
        }
        25% {
            transform: rotate(10deg);
        }
        50% {
            transform: rotate(0deg);
        }
        75% {
            transform: rotate(-10deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }

    .close-icon{
        width: 20px;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 9999;
    }
    .btn{
        width:80%;
        margin: 0 auto;
        display: block;
        margin-bottom: 30px;
    }
</style>
<style>
    .christmas-top .van-nav-bar__text{
        color: #ffffff !important;
    }
</style>